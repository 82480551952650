export const environment = {
  language: 'it',
  locale: 'it-IT',
  domain: 'dealandia.it',
  production: true,
  gtag_conversion: 'NONE',
  api_url: 'https://api.dlnd.it/libs/api/apiv2.php',
  assets_url: 'https://assets.dlnd.it',
  server_url: 'https://www.dealandia.it',
  upload_url: 'https://api.dlnd.it/libs/api/file_upload.php',
  twitter_url: 'https://twitter.com/DealandiaItalia',
  telegram_url: 'https://t.me/Dealandia',
  facebook_url: 'https://facebook.com/dealandia',
  kpw: 'pdJw2Fl13gFQQgtpoDSKgZfhcccfAPIu',
  ipv: 'ikaW6fWAFhuR6szf'
};
